.group {
  /* Alignments */
  &.alignFull {
    @apply align-full;

    & :global(.hero) {
      @apply [max-width:unset];
    }
  }

  &.alignWide {
    @apply align-wide;
  }

  /* Constrained (Group) */
  &.constrained {
    &.justifyCenter {
      & > *,
      & > :global(.image) img {
        @apply mx-auto;
      }
    }

    &.justifyLeft {
      & > * {
        @apply ml-0;
      }
    }

    &.justifyRight {
      & > *,
      & > :global(.image) img {
        @apply ml-auto mr-0;
      }
    }

    & > *:not([class*='alignWide']) {
      @apply container;

      max-width: var(--group-content-width);
    }

    & [class*='alignWide'] {
      @apply left-0 ml-auto mr-auto w-full;

      max-width: var(--group-wide-width);
    }
  }

  /* Flex (Row or Stack) */
  &.typeFlex {
    @apply flex gap-6;

    & > * {
      @apply my-0;
    }

    /* Vertical Alignment */
    &.verticalCenter {
      @apply items-center;
    }

    &.verticalTop {
      @apply items-start;
    }

    &.verticalBottom {
      @apply items-end;
    }

    /* Row */
    &:not(.orientationVertical) {
      &.justifyCenter {
        @apply justify-center;
      }

      &.justifyLeft {
        @apply justify-start;
      }

      &.justifyRight {
        @apply justify-end;
      }

      &.justifySpaceBetween {
        @apply justify-between;
      }
    }

    /* Stack */
    &.orientationVertical {
      &.justifyCenter {
        @apply items-center;
      }

      &.justifyLeft {
        @apply items-start;
      }

      &.justifyRight {
        @apply items-end;
      }
    }
  }

  /* Grid */
  &.typeGrid {
    @apply grid gap-6;

    grid-template-columns: repeat(var(--column-count), 1fr);

    &.withMinimumColumnWidth {
      grid-template-columns: repeat(
        auto-fill,
        minmax(min(var(--minimum-column-width), 100%), 1fr)
      );
    }

    & :global(.video-embed) {
      @apply m-0;
    }
  }

  &.flexWrap {
    @apply flex-wrap;
  }

  /* Stack */
  &.orientationVertical {
    @apply flex-col;
  }

  & :global(.heading),
  & :global(.tabs) {
    @apply my-0;
  }

  & p:has(a) {
    @apply print:hidden;
  }

  /* Mega Menu Item */
  &.isMegaMenuItem {
    & > :global(.heading) {
      @apply mt-5 font-primary font-medium uppercase tracking-wide text-gray-600;
    }

    & :global(.columns .heading) {
      @apply lg:font-secondary lg:text-base lg:uppercase;
    }

    & :global(.columns a):hover :global(.heading),
    & :global(.columns a):focus :global(.heading) {
      @apply text-red-base underline;
    }

    & :global(.columns .image span img) {
      @apply lg:object-cover;
    }

    & :global(.columns) {
      @apply lg:mt-4 lg:gap-x-5;
    }

    & :global(.columns .image) {
      @apply lg:mb-3;
    }
  }

  &.isMegaMenuItemAuctionsSecondary {
    & :global(.columns .heading) {
      @apply mb-2 font-body text-sm uppercase 2xl:text-base !important;
    }

    & :global(.columns a) {
      @apply block font-body text-xs font-normal leading-tight text-gray-600 no-underline hover:text-red-base hover:underline focus:text-red-base focus:underline 2xl:mr-2 2xl:text-sm;
    }

    & :global(.columns p) {
      @apply mb-2;
    }
  }

  /* Divisions & Upcoming Auctions Block Patterns */
  &.divisions,
  &.upcomingAuctions {
    @apply mb-8 xl:mb-32;

    & > :global(.columns) {
      @apply m-0 flex-wrap;

      & > :global(.columns__column) {
        @apply lg:shadow-[0_0_16px_rgb(0,0,0,0)] lg:[flex-direction:unset];

        &:hover,
        &:active {
          @apply lg:z-10 lg:-my-8 lg:border-b-[20px] lg:border-yellow lg:shadow-[0_0_16px_rgb(0,0,0)];

          & :global(.hero__overlay) {
            background: linear-gradient(
              0deg,
              rgb(0 0 0 / 100%) 40%,
              rgb(0 0 0 / 0%) 70%
            ) !important;
          }

          & :global(.hero__content) {
            @apply lg:mt-auto;
          }

          & :global(.button-group) {
            @apply lg:visible lg:opacity-100;
          }
        }

        & > :global(.hero) {
          @apply justify-end overflow-hidden p-5 lg:justify-start xl:p-8;

          & :global(.hero__link) {
            &:hover,
            &:active,
            &:focus {
              & ~ :global(.hero__content) {
                & :global(.button-group) {
                  @apply lg:visible lg:opacity-100;
                }
              }
            }
          }

          & :global(.hero__content) {
            @apply z-[12] flex flex-col items-center gap-2 lg:block;
          }

          & :global(.heading) {
            @apply my-0 font-normal first:font-bold lg:my-4 lg:text-left;
          }

          @media (width <= calc(theme('screens.lg'))) {
            & :global(.hero__overlay) {
              @apply !opacity-50;
            }
          }
        }
      }

      & :global(.button-group) {
        @apply hidden opacity-0 transition-all duration-700 ease-in-out lg:invisible lg:block;
      }

      & > :global(.columns__column),
      & :global(.hero),
      & :global(.hero__content),
      & :global(.heading) {
        @apply transition-all duration-300 ease-in-out;
      }
    }
  }

  /* Divisions Block Pattern */
  &.divisions {
    & > :global(.columns) {
      & > :global(.columns__column) {
        @apply !w-1/2 lg:!w-1/4;

        &:hover,
        &:active,
        &:focus {
          & :global(.heading) {
            @apply 3xl:!text-6xl;
          }
        }

        & > :global(.hero) {
          @apply !min-h-[200px] sm:!min-h-[380px];
        }
      }
    }
  }

  /* Upcoming Auctions Block Pattern */
  &.upcomingAuctions {
    & > :global(.columns) {
      & > :global(.columns__column) {
        @apply sm:!w-1/2 lg:!w-1/4;

        &:hover,
        &:active,
        &:focus {
          & :global(.heading):not([class*='_withColoredStripes_']) {
            @apply lg:!text-5xl xl:!text-6xl;
          }
        }

        & > :global(.hero) {
          @media (width <= calc(theme('screens.sm') - 1px)) {
            @apply !min-h-[250px];
          }

          @media (width >= calc(theme('screens.sm'))) and (width <= calc(theme('screens.lg') - 1px)) {
            @apply !min-h-[380px];
          }

          & :global(.hero__content) {
            @apply mx-auto lg:mx-0;
          }

          & :global(.heading):not([class*='_withColoredStripes_']) {
            @apply m-0;

            + :global(.image) {
              @apply my-4;
            }
          }

          & [class*='_withColoredStripes_'] {
            @apply font-body;
          }

          & :global(.columns) {
            @apply my-4 hidden lg:flex;
          }

          & p {
            @apply mb-0 font-primary text-xl lg:text-left;
          }
        }
      }
    }
  }

  /* Offset Media & Text Block Pattern */
  &.offsetMediaText {
    @apply my-8 xl:my-16;

    & :global(.media-text) {
      @apply !grid-cols-2 gap-10;

      &:last-of-type {
        & :global(.media-text__media) {
          @apply 2xl:-top-40 2xl:-mb-40;
        }
      }
    }

    & :global(.media-text--left) {
      & :global(.media-text__text) {
        @apply md:pl-10 md:pr-0 lg:pl-20;
      }
    }

    & :global(.media-text__text) {
      @apply items-stretch md:pl-0 md:pr-10 lg:pr-20;

      & :global(.columns) {
        @apply mb-5 mt-3;

        & :global(.columns__column) {
          @apply gap-3;
        }

        & :global(.columns__column),
        & :global(.heading) {
          @apply m-0;
        }
      }
    }

    & :global(.button-group) {
      @apply mt-0;
    }

    & :global(.button) {
      @apply flex-auto;
    }
  }

  /* Offset Full-width Columns Block Pattern */
  &.fullWidthColumns {
    & :global(.columns) {
      @apply gap-3;

      & :global(.columns__column) {
        @apply flex-row;
      }
    }

    & :global(.hero) {
      @apply p-[clamp(2rem,8vw,10rem)];

      & :global(.image) {
        @apply w-full max-w-[500px];
      }

      & :global(.hero__content) {
        @apply flex flex-col gap-8;

        & > * {
          @apply my-0;
        }
      }
    }

    & :global(.button) {
      @apply flex-auto lg:flex-initial;
    }
  }

  /* Auction Catalog Block Pattern */
  &.auctionCatalog {
    @apply my-8 xl:my-16;

    & :global(.tabs) {
      @apply mx-8 lg:mx-16;
    }

    & :global(.tabs__swiper) {
      @apply lg:w-[900px];
    }

    & :global(.tabs__button) {
      & :global(.tabs__button--active) {
        @apply border-b-milano-red;
      }
    }
  }

  /* Media Block Pattern */
  &.media {
    @apply relative my-8 xl:my-16;

    & :global(.tabs__tablist) {
      @apply lg:mx-0;
    }

    & :global(.tabs__swiper) {
      @apply m-0 lg:w-[900px];
    }

    & :global(.button-group) {
      @apply justify-center 2xl:absolute 2xl:right-0 2xl:top-40;
    }
  }

  /* Auction Hero Block Pattern */
  &.auctionHero {
    & :global(.hero) {
      @apply pt-20;
    }

    & :global(.hero__content) {
      @apply max-w-[800px];

      & > p:first-of-type {
        @apply mb-0 mt-2;
      }

      & > p:not(:last-of-type) {
        @apply text-center font-primary uppercase tracking-wider text-white;
      }
    }

    & :global(.button) {
      @apply lg:flex-1;
    }

    & :global(.image) {
      @apply max-w-[450px];
    }
  }

  /* Lot Section */
  &.lotSection {
    @apply container space-y-4 pb-14 pt-4 [background-color:inherit];
    @apply md:space-y-6 md:pb-16 md:pt-14;

    & :global(.image-gallery) + :global(.heading) {
      @apply !mt-12;
    }

    & :global(.heading) {
      @apply font-secondary;
    }

    & h2 {
      @media (width <= calc(theme('screens.md') - 1px)) {
        @apply !text-3xl;
      }
    }

    & h3 {
      @media (width <= calc(theme('screens.md') - 1px)) {
        @apply !text-xl;
      }
    }

    & :global(.columns) {
      @apply justify-center gap-4 last-of-type:border-none;
      @apply md:gap-8 md:border-b md:border-b-gray-100 md:pb-6;

      & :global(.columns__column) {
        @apply empty:hidden lg:max-w-[25%];

        & > p {
          &:first-of-type {
            @media (width <= calc(theme('screens.md') - 1px)) {
              @apply !text-base;
            }
          }

          &:last-of-type {
            @media (width <= calc(theme('screens.md') - 1px)) {
              @apply !text-2xl;
            }
          }
        }
      }

      & :global(.image) {
        @apply mb-4 md:mb-8;
      }

      & p {
        @apply m-0 font-secondary;
      }

      /* Print Styles for displaying .lotSection in 2 columns. */
      @media print {
        @apply grid grid-cols-2 border-b-0;

        & + :global(.columns) {
          @apply mt-0;
        }

        & :global(.columns__column) {
          @apply max-w-full;
        }
      }
    }

    & ul {
      @apply mb-4 list-none;

      & > li {
        @apply relative mb-4 pl-9 md:mb-6;
        @apply before:absolute before:left-1 before:top-[10px] before:block before:h-[10px] before:w-[10px] before:rounded-full before:bg-gray-500;
        @apply after:absolute after:left-0 after:top-[6px] after:h-[18px] after:w-[18px] after:rounded-full after:border after:border-gray-400;
      }
    }

    & :global(.sale-conditions) {
      @apply !mb-4 !mt-12;

      > * {
        @apply px-0 sm:px-5;
      }

      & :global(.heading) {
        @apply font-primary font-normal;
      }

      & p {
        @apply my-4 leading-4;
      }
    }
  }

  /* Lot Slot */
  &:global(.slot) {
    & > .lotSection {
      @apply pt-0;
    }
  }

  /* Exhibitors List Block Pattern */
  &.exhibitorsList {
    @apply py-10 md:py-20;

    & :global(.heading),
    & :global(.button) {
      @apply font-secondary;
    }

    & :global(h2.heading) {
      @apply mb-2.5;
    }

    & :global(.logos) {
      @apply mt-6 grid grid-cols-2 items-center gap-6 sm:grid-cols-[repeat(auto-fit,_minmax(180px,_1fr))] md:mt-10 md:gap-8 lg:gap-10;
    }

    & :global(.image) {
      @apply !m-0 aspect-[auto];
    }
  }

  /* Icon Navigation Block Pattern */
  &.iconNavigation {
    @apply py-10 md:pt-20;

    & :global(.tabs) {
      & :global(.tabs__tablist) {
        @apply relative mx-0 pb-[22px] before:absolute before:bottom-0 before:left-0 before:ml-[calc(50%-50vw)] before:block before:h-[1px] before:w-screen before:bg-gray-400 before:content-[''];

        & :global(.swiper-wrapper) {
          @apply sm:justify-between;
        }

        & :global(.tabs__button) {
          @apply items-start border-b-0;

          & button {
            @apply block border-b-0 p-0 opacity-[0.42] grayscale transition-all;
            @apply font-secondary text-sm font-normal uppercase text-black [word-break:break-word];
            @apply pl-3 pr-3 sm:px-0;
            @apply hover:text-gray-800 hover:opacity-100 hover:grayscale-0;
            @apply focus:text-gray-800 focus:opacity-100 focus:grayscale-0;

            &:global(.tabs__button--active) {
              @apply text-gray-800 opacity-100 grayscale-0;
            }

            & img {
              @apply mb-2.5 ml-auto mr-auto max-h-[30px] !w-auto;
            }
          }
        }
      }

      & [role='tabpanel'] {
        @apply pt-8 sm:pt-12;

        & :global(.heading),
        & :global(.button) {
          @apply font-secondary;
        }

        & :global(h2.heading) {
          @apply mb-2.5;
        }

        & :global(.button) {
          @apply tracking-normal;
        }

        & :global(.columns__column) {
          & :global(h3.heading) {
            @apply my-2 first:mt-0;
          }

          & p + :global(h3.heading) {
            @apply mt-0;
          }

          & p + :global(.button-group) {
            @apply mt-3;
          }

          & :global(.image__caption) {
            @apply text-left;
          }
        }
      }
    }
  }

  /* Sponsors Grid Block Pattern */
  &.sponsorsGrid {
    @apply relative py-10 md:pb-20 md:pt-[75px];
    @apply bg-auto bg-repeat before:absolute before:bottom-0 before:left-0 before:z-[-1] before:ml-[calc(50%-50vw)] before:block before:h-full before:w-screen before:bg-gray-800 before:content-[''];

    &::before {
      background: var(--image-url);
    }

    & :global(.heading),
    & :global(.button) {
      @apply font-secondary;
    }

    & :global(h2.heading) {
      @apply mb-2.5;
    }

    & :global(.sponsors) {
      @apply mt-6 grid grid-cols-1 gap-[22px] sm:grid-cols-2 md:mt-10 lg:grid-cols-3;
    }

    & :global(.hero) {
      @apply justify-end p-5;

      &:hover {
        p {
          @apply block;
        }
      }

      &:not(:hover) {
        & :global(.hero__overlay) {
          @apply !opacity-0 transition-opacity duration-200;
        }
      }

      & :global(.hero__overlay) {
        @apply transition-opacity duration-200;
      }

      & :global(.hero__content) {
        @apply w-full;
      }

      & :global(.heading) {
        @apply inline-block !text-3xl;

        &[class*='_withColoredStripes_'] {
          @apply pl-7;
          @apply before:left-1.5 before:top-[5px] before:h-6 before:w-[3px] before:bg-red-base;
          @apply after:left-3 after:top-[5px] after:h-6 after:w-[3px] after:bg-red-base;
        }

        &:focus-within {
          + p {
            @apply block;
          }
        }

        & :global(a) {
          @apply no-underline;
        }
      }

      p {
        @apply mb-2 mt-1 hidden;
      }

      & :global(.button-group) {
        @apply mb-0 mt-3;
      }

      & :global(.button) {
        @apply text-sm no-underline;

        &:hover {
          & svg {
            @apply ml-3 transition-all;
          }
        }

        & svg {
          @apply ml-1 h-5 transition-all;
        }
      }
    }
  }

  /* TV Times / Schedule Block Pattern */
  &.tvTimes {
    & :global(.heading),
    & :global(.button) {
      @apply font-secondary;
    }

    & :global(h2.heading) {
      @apply mb-2.5;
    }

    & :global(.button) {
      @apply tracking-normal;
    }

    & :global(.columns) {
      @apply my-6 bg-gray-800 md:my-10;
    }

    & :global(.columns__column) {
      @apply flex;

      &:not(:last-of-type) {
        @apply first-of-type:hidden 2xl:first-of-type:flex;
      }

      & :global(.hero) {
        @apply h-full p-12;
      }

      & :global(.hero__overlay) {
        @apply !bg-transparent bg-gradient-to-t from-black to-transparent !opacity-50;
      }

      & :global(.hero__content) {
        @apply ml-auto mr-auto max-w-[335px];

        & :global(.image) {
          @apply mb-5;
        }

        & :global(.button-group) {
          @apply mb-0 mt-10 gap-[22px];
        }

        & :global(.button) {
          @apply min-w-[250px];
        }
      }
    }

    & table {
      @apply mb-0 text-sm text-gray-400;

      & thead {
        @apply hidden sm:table-header-group;

        & tr {
          @apply border-b-2 border-solid border-gray-700;
        }
      }

      & tbody tr:nth-child(odd) {
        @apply bg-transparent;
      }

      & tr {
        @apply flex flex-wrap py-3 sm:table-row md:py-0;

        + tr {
          @apply border-t border-solid border-gray-700;
        }
      }

      & th,
      & td {
        @apply flex-auto px-5 py-2 sm:p-5;
        @apply first-of-type:w-full sm:first-of-type:w-auto sm:first-of-type:pl-7 sm:last-of-type:pr-7 lg:first-of-type:pl-10 lg:last-of-type:pr-10;

        & img {
          @apply sm:ml-auto sm:mr-auto;
        }
      }

      & strong {
        @apply uppercase text-white;
      }

      /* Custom print styles for TV Times table. */
      @media print {
        & thead {
          /* Hide TV times table header on print. */
          @apply hidden;
        }

        & tr {
          @apply !flex flex-wrap border-opacity-25 px-1.5 py-3;
        }

        & td {
          @apply w-1/2 !p-1.5;

          &:first-of-type,
          &:last-of-type {
            /* Display title and tv column on separate lines. */
            @apply !w-full;

            & a {
              /* Enlarge title column. */
              @apply text-lg no-underline;
            }
          }

          & img {
            /* Apply max width on images for printing. */
            @apply m-0 max-w-[220px];
          }
        }
      }
    }
  }

  /* Giveaway CTA Block Pattern */
  &.giveawayCta {
    @apply relative bg-auto bg-repeat py-8 md:py-0;
    @apply before:absolute before:left-0 before:z-[-1] before:ml-[calc(50%-50vw)] before:hidden before:h-full before:w-screen before:content-[''] md:before:block;

    &::before {
      background: var(--image-url);
    }

    & :global(.button) {
      @apply font-secondary tracking-normal;
    }

    & :global(.media-text) {
      @apply mb-0;

      & :global(.media-text__media) {
        @apply aspect-auto;
      }

      &:global(.media-text--left) {
        & :global(.media-text__text) {
          @apply mr-0;
        }

        & + :global(.hero) {
          @apply md:left-1/2 md:right-auto;
        }
      }

      &:not(:global(.media-text--left)) {
        & :global(.media-text__text) {
          @apply ml-0;
        }
      }

      & :global(.media-text__text) {
        @apply border-[10px] border-solid border-[rgba(255,255,255,0.15)] p-8 md:m-8 lg:m-[40px] 2xl:m-[60px] 2xl:p-[50px];

        & :global(.heading) {
          @apply mb-5 text-5xl leading-[0.95] sm:text-[68px] 2xl:text-[90px];

          & mark {
            @apply text-6xl sm:text-[86px] 2xl:text-[120px];
          }
        }
      }

      & :global(.button-group) {
        @apply mb-0 mt-6 sm:mt-9;
      }
    }

    & :global(.hero) {
      @apply absolute right-auto top-0 z-[-1] ml-[calc(50%-50vw)] h-full !min-h-0 w-screen md:right-1/2 md:ml-0 md:w-[calc(100vw/2)];
    }
  }

  /* Hotels List Block Pattern */
  &.hotelsList {
    & :global(.heading),
    & :global(.button) {
      @apply font-secondary;
    }

    & :global(h2.heading) {
      @apply mb-2.5;
    }

    & :global(.button-group) {
      @apply mb-0 mt-10;
    }

    & :global(.button) {
      @apply tracking-normal;
    }

    & :global(.hotel) {
      @apply mt-10 gap-6 first-of-type:mt-6 md:first-of-type:mt-10 lg:gap-10;

      & :global(h3.heading) {
        + p {
          @apply mt-2 text-sm;

          & a {
            @apply no-underline;
          }
        }
      }

      & :global(.button-group) {
        @apply mt-[22px] md:mt-10;
      }
    }

    & :global(.icons) {
      @apply mt-[6px] flex flex-wrap gap-[22px];

      & :global(.image) {
        @apply !m-0;
      }
    }
  }

  /* Events Schedule Block Pattern */
  &.eventsSchedule {
    & :global(.heading),
    & :global(.button) {
      @apply font-secondary;
    }

    & :global(h2.heading) {
      @apply mb-2.5;
    }

    & :global(h3.heading) {
      @apply mb-2;
    }

    & :global(.button-group) {
      @apply mb-0 mt-10;
    }

    & :global(.button) {
      @apply tracking-normal;
    }

    & :global([class*='_accordion_']) {
      @apply my-6 rounded-xl border-none bg-gray-800 md:my-10;

      & :global(details) {
        @apply p-0 px-6 lg:px-10;

        &:first-of-type {
          & :global(summary) {
            @apply rounded-xl rounded-b-none;
          }
        }

        &:global([open]) {
          @apply pb-6;

          & :global(summary) {
            @apply mb-6 bg-red-base transition-colors;

            & :global(.heading) {
              @apply text-white;
            }
          }
        }

        & :global([class*='_table_']) {
          @apply mt-6 border-t border-solid border-gray-700 pt-6 first-of-type:mt-0 first-of-type:border-none first-of-type:pt-0 sm:pt-[11px];

          & + :global(p) {
            @apply m-0 text-sm;
          }
        }

        & :global(table) {
          @apply mb-0;

          thead tr {
            @apply border-none;
          }

          & th,
          & td {
            @apply w-full pl-0 pr-0 pt-0 align-top first-of-type:pl-0 sm:w-1/3 sm:pl-4 lg:first-of-type:w-[24%] lg:last-of-type:w-auto;
          }

          & :global(th) {
            @apply hidden pb-2.5 text-base font-normal text-gray-400 sm:table-cell sm:pb-0;
          }

          & td {
            @apply block pb-2.5 pt-0 sm:table-cell sm:pt-2.5 md:text-lg;
          }

          & tr:nth-child(odd) {
            @apply bg-transparent;
          }
        }
      }

      & :global(summary) {
        @apply mx-[-24px] bg-gray-675 px-6 py-[22px] transition-colors hover:bg-gray-650 lg:mx-[-40px] lg:px-10;

        & :global(.heading) {
          @apply m-0 text-[21px] text-gray-400;
        }

        & :global(.arrow) {
          @apply before:bg-white after:bg-white;
        }
      }
    }
  }

  /* Featured Lots Block Pattern */
  &.featuredLots {
    @apply py-10 md:py-20;

    & :global(h2.heading) {
      @apply mb-6 font-secondary md:mb-10;
    }
  }
}
